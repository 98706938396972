import axios from 'axios';

const service = axios.create({
    baseURL: 'https://proxy.diuzui.com/NavServe', // api的base_url
    timeout: 360000, // 请求超时时间，目前设定超时时间是1分钟
    withCredentials: true
});

// respone拦截器
service.interceptors.response.use(
    response => {
        if (response.status === 200) {
            return response.data
        } else {
            return Promise.reject(response)
        }
    },
    error => {
        return Promise.reject(error);
    }
);

export default service;
