<template>
    <div class="dns"></div>
</template>

<script>
    export default {
        name: "DNS"
    }
</script>

<style scoped lang="scss">

</style>
