<template>
    <div class="agent">
        <el-form ref="agentForm" :model="agentForm" :rules="agentRules" :inline="true" label-width="50px">
            <el-form-item label="链接" prop="link">
                <el-input size="small" v-model="agentForm.link"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button size="mini" type="primary" @click="agentSubmit" :loading="loading">访问</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    export default {
        name: "Agent",
        data() {
            return {
                agentForm: {
                    link: ''
                },
                agentRules: {
                    link: [

                    ]
                }
            }
        },
        methods: {
            agentSubmit() {
                window.open(this.agentForm.link);
            }
        }
    }
</script>

<style scoped lang="scss">

</style>
