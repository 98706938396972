<template>
    <div class="ntp"></div>
</template>

<script>
    export default {
        name: "NTP"
    }
</script>

<style scoped lang="scss">

</style>
