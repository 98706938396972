<template>
    <div class="random"></div>
</template>

<script>
    export default {
        name: "Random"
    }
</script>

<style scoped lang="scss">

</style>
