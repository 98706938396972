import request from '@/utils/util'

export const getList = () => request({
    url: '/getList',
    method: 'get'
});

export const getType = () => request({
    url: '/getType',
    method: 'get'
});

export const login = (data) => request({
    url: '/login',
    method: 'post',
    data
});

export const getUserInfo = () => request({
    url: '/getUserInfo',
    method: 'post'
});

export const addLink = (data) => request({
    url: '/addLink',
    method: 'post',
    data
});

export const delLink = (data) => request({
    url: '/delLink',
    method: 'post',
    data
});

export const logout = (data) => request({
    url: '/logout',
    method: 'post',
    data
});
