<template>
    <div class="add-link">
        <el-form ref="addForm" :model="addForm" :rules="addRules" :inline="true" label-width="50px">
            <el-form-item label="分类" prop="type">
                <el-select size="small" v-model="addForm.type" placeholder="请选择">
                    <el-option value="" selected>请选择</el-option>
                    <el-option
                            v-for="item in typeData"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="名称" prop="name">
                <el-input size="small" v-model="addForm.name"></el-input>
            </el-form-item>
            <el-form-item label="链接" prop="url">
                <el-input size="small" v-model="addForm.url"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button size="mini" type="primary" @click="addLink" :loading="loading">提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import {addLink} from '@/api/app';

    export default {
        name: "AddLink",
        data() {
            return {
                loading: false,
                addForm: {
                    name: '',
                    url: '',
                    type: ''
                },
                addRules: {
                    name: [
                        { required: true, message: '请填写名称', trigger: 'blur'}
                    ],
                    url: [
                        { required: true, message: '请填写链接', trigger: 'blur'}
                    ],
                    type: [
                        { required: true, message: '请选择分类', trigger: 'change'}
                    ]
                }
            }
        },
        props: {
            ParentData: {}
        },
        computed: {
            typeData: function () {
                return this.ParentData.typeData
            }
        },
        methods: {
            addLink() {
                this.$refs['addForm'].validate(valid => {
                    if (valid) {
                        this.loading = true;
                        addLink({
                            type: this.addForm.type,
                            name: this.addForm.name,
                            url: this.addForm.url,
                        }).then(res => {
                            if (res.status) {
                                this.$message.success(`添加成功`);
                                this.$parent.getList();
                                this.addForm.name = '';
                                this.addForm.url = '';
                            } else {
                                this.$message.error(res.msg);
                            }
                            this.loading = false;
                        }).catch(err => {
                            this.loading = false;
                        })
                    }
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .add-link {
        .el-form-item { margin-bottom: 0;}
        .el-select { width: 120px;}
        /deep/ .el-input__inner { padding: 0 5px;}
    }
</style>
