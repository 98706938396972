<template>
    <div class="login">
        <el-form ref="loginForm" :model="loginForm" :rules="loginRules" :inline="true" label-width="50px">
            <el-form-item label="账号" prop="username">
                <el-input size="small" v-model="loginForm.username"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
                <el-input type="password" size="small" v-model="loginForm.password" @keydown.enter.native="login"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button size="small" type="primary" @click="login" :loading="loading">登录</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import md5 from "md5";
    import {login} from '@/api/app';

    export default {
        name: "Login",
        data() {
            return {
                loading: false,
                loginForm: {
                    username: '',
                    password: ''
                },
                loginRules: {
                    username: [
                        { required: true, message: '请填写账号', trigger: 'blur'}
                    ],
                    password: [
                        { required: true, message: '请填写密码', trigger: 'blur'}
                    ],
                }
            }
        },
        methods: {
            login() {
                this.$refs['loginForm'].validate(valid => {
                    if(valid){
                        this.loading = true;
                        login({
                            username: this.loginForm.username,
                            password: (md5(this.loginForm.password)).toLocaleUpperCase()
                        }).then(res => {
                            if (res.status) {
                                history.go(0)
                            } else {
                                this.loading = false;
                                this.$message.error('登录失败');
                            }
                        }).catch(err => {
                            this.loading = false;
                        })
                    }
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .login {
        margin: 20px 0;
        .el-form-item { margin-bottom: 0;}
        /deep/ .el-input__inner { padding: 0 5px;}
    }
</style>
